import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Notiflix from "notiflix";
import querystring from "querystring";
import ReactPlayer from "react-player";

export default function CustomEntry() {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [fixture, setFixture] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [tournament, setTournament] = useState(null);
  const [matches, setMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clipsUrl, setClipsUrl] = useState("");
  const [publishOnFL, setPublishOnFL] = useState(false);
  const [publishOnHL, setPublishOnHL] = useState(true);

  Notiflix.Notify.init({
    position: "center-bottom",
  });

  const fetchFixture = async (date) => {
    setFixture([]);
    setTournament(null);
    setMatches([]);
    setSelectedMatch(null);

    try {
      const selectedDate = new Date(date).toISOString().split("T")[0];

      const { data } = await axios.get(
        `https://footylight.azurewebsites.net/api/Match?matchDay=${selectedDate}`
      );

      setFixture(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFixture(date);
    // eslint-disable-next-line
  }, []);

  const getMatchInfo = async () => {
    const tournamentId = matches[selectedMatch].TournamentID;
    const matchId = matches[selectedMatch].ID;
    const localTeamId = matches[selectedMatch].LocalTeam.ID;
    const visitorTeamId = matches[selectedMatch].VisitorTeam.ID;

    return { tournamentId, matchId, localTeamId, visitorTeamId };
  };

  const getVideoData = async (
    title,
    url,
    matchId,
    tournamentId,
    localTeamId,
    visitorTeamId
  ) => {
    let data = {
      VideoID: `rssfeatured-${Date.now()}`,
      Source: "5",
      Category: "5",
      MatchID: matchId,
      TournamentID: tournamentId,
      LocalTeamID: localTeamId,
      VisitorTeamID: visitorTeamId,
      Title: title,
      Link: url,
      Thumb: `https://2ebknvc5ta.execute-api.us-east-1.amazonaws.com/default/${Date.now()}?text=${encodeURIComponent(
        title.trim()
      )}`,
      ContentType: "browser",
      ContentUrl: url,
      DmcaLabel: "footylight.com",
      DmcaContent: url,
      DmcaDisclaimer: "https://bit.ly/footylight-dmca",
      OrgnName: "",
      Description: "",
      Duration: "",
      AdEnabled: "1",
      PushSentOn: new Date().toISOString(),
      Disabled: "0",
      SortOrder: "0",
      publishedTime: new Date().toISOString(),
      expiretime: "",
      Created: new Date().toISOString(),
      Updated: new Date().toISOString(),
      Showcase: "",
      Trending: "",
      EncodedString: "",
      EncodedLong: "",
      Language: "",
      ContentExtra: "",
    };

    return data;
  };

  const getHighlightsData = async (
    title,
    url,
    matchId,
    tournamentId,
    localTeamId,
    visitorTeamId,
    matchDate,
    clipsUrl
  ) => {
    return {
      category: "highlights",
      title: title,
      videoThumb: `https://2ebknvc5ta.execute-api.us-east-1.amazonaws.com/default/${Date.now()}?text=${encodeURIComponent(
        title.trim()
      )}`,
      videoUrl: url,
      matchId: matchId,
      tournamentId: tournamentId,
      localTeamId: localTeamId,
      visitorTeamId: visitorTeamId,
      matchDate: matchDate,
      clipsUrl: clipsUrl,
      geo: [],
    };
  };

  const publish = async (e) => {
    e.preventDefault();
    setLoading(true);

    const initialVideoData = await getVideoData(title, url, "", "", "", "");
    const initialVideoId = initialVideoData.VideoID;

    const { tournamentId, matchId, localTeamId, visitorTeamId } =
      await getMatchInfo();
    const finalVideoData = await getVideoData(
      title,
      `https://clips.footylight.com/external/${initialVideoId}`,
      matchId,
      tournamentId,
      localTeamId,
      visitorTeamId
    );
    const finalHighlightsData = await getHighlightsData(
      title,
      url,
      matchId,
      tournamentId,
      localTeamId,
      visitorTeamId,
      date,
      `https://clips.footylight.com/external/${initialVideoId}`
    );

    let initialConfig = {
      method: "post",
      url: "https://footylight.azurewebsites.net/api/Video",
      headers: {
        "auth-token": "footylight",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: querystring.stringify(initialVideoData),
    };

    let finalConfig = {
      method: "post",
      url: "https://footylight.azurewebsites.net/api/Video",
      headers: {
        "auth-token": "footylight",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: querystring.stringify(finalVideoData),
    };

    let highlightsConfig = {
      method: "post",
      url: "https://3u3stlxqn3.execute-api.us-east-1.amazonaws.com/prod/createRecord",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: querystring.stringify(finalHighlightsData),
    };

    axios(initialConfig)
      .then(async (response) => {
        if (publishOnFL && publishOnHL) {
          axios(finalConfig)
            .then(async (response) => {
              axios(highlightsConfig)
                .then(async (response) => {
                  Notiflix.Notify.success("Published Successfully");
                  setClipsUrl(
                    `https://clips.footylight.com/external/${initialVideoId}`
                  );
                  setLoading(false);
                  return;
                })
                .catch((error) => {
                  console.log(error);
                  Notiflix.Notify.failure("Publishing Failed");
                  setLoading(false);
                  return;
                });
            })
            .catch((error) => {
              console.log(error);
              Notiflix.Notify.failure("Publishing Failed");
              setLoading(false);
              return;
            });
        } else if (publishOnFL && !publishOnHL) {
          axios(finalConfig)
            .then(async (response) => {
              Notiflix.Notify.success("Published Successfully");
              setClipsUrl(
                `https://clips.footylight.com/external/${initialVideoId}`
              );
              setLoading(false);
              return;
            })
            .catch((error) => {
              console.log(error);
              Notiflix.Notify.failure("Publishing Failed");
              setLoading(false);
              return;
            });
        } else if (publishOnHL && !publishOnFL) {
          axios(highlightsConfig)
            .then(async (response) => {
              Notiflix.Notify.success("Published Successfully");
              setClipsUrl(
                `https://clips.footylight.com/external/${initialVideoId}`
              );
              setLoading(false);
              return;
            })
            .catch((error) => {
              console.log(error);
              Notiflix.Notify.failure("Publishing Failed");
              setLoading(false);
              return;
            });
        }
      })
      .catch((error) => {
        console.log(error);
        Notiflix.Notify.failure("Publishing Failed");
        setLoading(false);
        return;
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Custom Entry | FootyLight RSS Dashboard</title>
      </Helmet>

      <div className="row">
        <div className="col-md-6">
          <form className="pr-3" onSubmit={publish}>
            <div className="mb-3">
              <label className="form-label">Video Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
                placeholder="Enter the video title..."
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Video URL</label>
              <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="form-control"
                placeholder="Enter the video url..."
              />
            </div>
            <div className="mb-3">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label for="inputCity">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputCity"
                    value={date}
                    onChange={(e) => {
                      setDate(e.target.value);
                      fetchFixture(e.target.value);
                      setTournament(-1);
                    }}
                    disabled={fixture === []}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label for="inputState">Tournament</label>
                  <select
                    id="inputState"
                    className="form-control"
                    disabled={fixture === [] || !date}
                    value={tournament}
                    onChange={(e) => {
                      setTournament(e.target.value);
                      setMatches(fixture[e.target.value].Matches);
                      setSelectedMatch(-1);
                    }}
                  >
                    <option disabled selected value="-1">
                      Choose...
                    </option>
                    {fixture.map((item, index) => (
                      <option value={index}>{item.Name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label for="inputState">Match</label>
                  <select
                    id="inputState"
                    className="form-control"
                    disabled={fixture === [] || tournament === null}
                    value={selectedMatch}
                    onChange={(e) => setSelectedMatch(e.target.value)}
                  >
                    <option selected disabled value="-1">
                      Choose...
                    </option>
                    {matches.map((item, index) => (
                      <option value={index}>{`${item.LocalTeam.Name} (${
                        item.LocalTeam.Score || "?"
                      } : ${item.VisitorTeam.Score || "?"}) ${
                        item.VisitorTeam.Name
                      }`}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    checked={publishOnFL}
                    onClick={() => setPublishOnFL(!publishOnFL)}
                  />
                  <label class="form-check-label" for="inlineCheckbox1">
                    Publish on App/Website
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox2"
                    checked={publishOnHL}
                    onClick={() => setPublishOnHL(!publishOnHL)}
                  />
                  <label class="form-check-label" for="inlineCheckbox2">
                    Publish on Highlights page
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <button
                  className="btn btn-success text-dark"
                  type="submit"
                  disabled={
                    loading ||
                    matches === [] ||
                    selectedMatch === null ||
                    selectedMatch === -1 ||
                    title === "" ||
                    url === "" ||
                    clipsUrl !== "" ||
                    (!publishOnFL && !publishOnHL)
                  }
                >
                  {loading ? "Publishing..." : "Publish"}
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setTitle("");
                    setUrl("");
                    setClipsUrl("");
                  }}
                  disabled={loading}
                >
                  Reset
                </button>
              </div>
              {clipsUrl !== "" ? (
                <div className="alert alert-success" role="alert">
                  Clips page URL:{" "}
                  <a href={clipsUrl} target="_blank" rel="noopener noreferrer">
                    {clipsUrl}
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>

        <div className="col-md-6">
          <h4>Preview</h4>
          <div style={{ "--aspect-ratio": "16/9", marginBottom: "20px" }}>
            {url !== "" ? (
              <ReactPlayer
                url={url}
                width="100%"
                height="100%"
                playing
                controls
                volume={0}
                muted
              />
            ) : (
              <img src="./thumbnail.svg" alt="Thumbnail" />
            )}
          </div>
          {title !== "" ? (
            <div>
              <h5>{title}</h5>
              <small>By Footylight</small>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
