import { css } from "@emotion/react";
import GridLoader from "react-spinners/GridLoader";

const override = css`
  display: block;
  margin: auto;
  border-color: red;
  size: 200;
`;
function Spinner() {
  return (
    <div className="sweet-loading">
      <GridLoader color={"#77e046"} loading={true} css={override} size={100} />
    </div>
  );
}

export default Spinner;