 export const categoryOptions= [
  { label: "Not Available", value: 0 },
  { label: "Highlights", value: 1 },
  { label: "Goal Clips", value: 2 },
  {
    label: "Press Conference (Post Match)",
    value: 3,
  },
  {
    label: "Press Conference (Pre Match) ",
    value: 4,
  },
  { label: "Training Video", value: 5 },
  { label: "Post Match Reaction", value: 6 },
  { label: "Behind the Scene", value: 7 },
  { label: "Rumors", value: 8 },
  {
    label: "Match Analysis (Post Match)",
    value: 9,
  },
  {
    label: "kit",
    value: 10,
  },
  { label: "Pre match analysis", value: 11 },
  { label: "Match review", value: 12 },
  { label: "Experts opinion", value: 13 },
  { label: "Transfer", value: 14 },
  { label: "Player Interview ", value: 15 },
  { label: "Coach Interview", value: 16 },
  { label: "Others", value: 17 },
];
export const significantOptions = [
  { value: "title", label: "Title" },
  { value: "description", label: "Description" },
  { value: "thumbnail", label: "Thumbnail" },
];
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  categoryOptions,
  significantOptions
}