import React from "react";
import ReactPlayer from "react-player";


export default function ContentCard({ results }) {
  console.log({results})
  const youTubeIdFromLink = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
  };
  const dugoutIdfromContent = (content) => {
    const id = content.split("dugout-embed-")[1].split('">')[0];
    return id;
  };
  const getEmbed = (source, url, title, body) => {
    if (source === "YouTube") {
      return (
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${youTubeIdFromLink(url)}`}
          title={title}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      );
    }
    if (source === "Facebook") {
      return (
        <iframe
          src={`https://www.facebook.com/plugins/post.php?height=420&href=${url}&show_text=false&width=740`}
          width="100%"
          height="420px"
          title={title}
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameborder="0"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      );
    }
    if (source === "Instagram") {
      return (
        <iframe
          src={`${url}/embed`}
          height="420px"
          title={title}
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameborder="0"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          allowFullScreen
          allowtransparency
        ></iframe>
      );
    }
    if (source === "Dugout") {
      return (
        <iframe
          width="100%"
          height="100%"
          src={`https://embed.dugout.com/v2/?p=${dugoutIdfromContent(body)}`}
          title={title}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      );
    }
    if (source === "Opta") {
      return (
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          playing
          controls
          volume={0}
          muted
        />
      );
    }
  };
  return (
    <div>
      <div className="row ">
        <div className="col-md-6 justify-content-center embed-responsive embed-responsive-21by9">
          {getEmbed(
            results.source,
            results.content_url,
            results.title,
            results.content_body
          )}
        </div>
        <div className="col-md-6">
          <img
            src={results.thumbnail}
            alt={results.title}
            height="100%"
            width="100%"
          ></img>
        </div>
      </div>
      <div className="row mt-5">
        <h2>Title: {results.title}</h2>
      </div>
      <div className="row mt-2">
        <h4>Description: {results.snippet}</h4>
      </div>
      <div className="row mt-2">
        <h4>Creator: {results.creator}</h4>
      </div>
      <div className="row mt-2">
        <h4>
          <a href={results.content_url}>Video Link</a>
        </h4>
      </div>
    </div>
  );
}
