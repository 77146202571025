import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Datetime from "react-datetime";
import axios from "axios";
import Notiflix from "notiflix";
import ContentCard from "../ContentFinder/ContentCard";

const ES_URL = process.env.REACT_APP_ES_URL;
const ES_USERNAME = process.env.REACT_APP_ES_USERNAME;
const ES_PASSWORD = process.env.REACT_APP_ES_PASSWORD;

export default function PublishedContent() {
  const [query, setQuery] = useState("");

  var oldDate = new Date();
  oldDate.setDate(oldDate.getDate() - 30);

  const [from, setFrom] = useState(oldDate.toISOString());
  const [to, setTo] = useState(new Date().toISOString());

  const [results, setResults] = useState({});

  const getBody = async (q, f, t) => {
    const body = {
      version: true,
      size: 500,
      sort: [
        {
          published_on: {
            order: "desc",
            unmapped_type: "boolean",
          },
        },
      ],
      aggs: {
        2: {
          date_histogram: {
            field: "published_on",
            fixed_interval: "30d",
            time_zone: "Asia/Dhaka",
            min_doc_count: 1,
          },
        },
      },
      stored_fields: ["*"],
      script_fields: {},
      docvalue_fields: [
        {
          field: "published_on",
          format: "date_time",
        },
      ],
      _source: {
        excludes: [],
      },
      query: {
        bool: {
          must: [],
          filter: [
            q === ""
              ? ""
              : {
                  multi_match: {
                    type: "best_fields",
                    query: q,
                    lenient: true,
                  },
                },
            {
              match_phrase: {
                published_on_app: true,
              },
            },
            {
              match_phrase: {
                is_relevant: true,
              },
            },
            {
              range: {
                published_on: {
                  gte: f,
                  lte: t,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
      highlight: {
        pre_tags: ["@kibana-highlighted-field@"],
        post_tags: ["@/kibana-highlighted-field@"],
        fields: {
          "*": {},
        },
        fragment_size: 2147483647,
      },
    };

    return body;
  };

  const handleSearch = async (e) => {
    e?.preventDefault();

    Notiflix.Block.dots("div#result-block", "Loading...", {
      svgSize: "60px",
      svgColor: "#444444",
      messageColor: "#444444",
      backgroundColor: "#F3F6F9DD",
    });

    const body = await getBody(query, from, to);

    await axios
      .post(`${ES_URL}/_search?pretty=true`, JSON.stringify(body), {
        headers: {
          "Content-type": "application/json",
        },
        auth: {
          username: ES_USERNAME,
          password: ES_PASSWORD,
        },
      })
      .then((response) => {
        setResults(response.data);
        Notiflix.Block.remove("div#result-block");
      })
      .catch((err) => {
        console.log(err);
        Notiflix.Report.failure("Something went wrong");
      });
  };

  React.useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Published Content | FootyLight RSS Dashboard</title>
      </Helmet>

      <div className="row">
        <div className="col-sm-12">
          <div
            className="card"
            style={{
              position: "sticky",
              top: 20,
              zIndex: "98",
            }}
          >
            <div className="card-block p-1">
              <form onSubmit={handleSearch}>
                <div className="row align-items-center justify-content-center">
                  <input
                    className="col-sm-6 form-control m-2"
                    type="text"
                    placeholder="Search for any content..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <Datetime
                    className="col-sm-2 m-2 p-0 form-control border-0"
                    value={Date.parse(from)}
                    onChange={(e) => setFrom(new Date(e._d).toISOString())}
                    required
                  />
                  <i className="feather icon-arrow-right"></i>
                  <Datetime
                    className="col-sm-2 m-2 p-0 form-control border-0"
                    value={Date.parse(to)}
                    onChange={(e) => setTo(new Date(e._d).toISOString())}
                    required
                  />
                  <button
                    className="col-sm-1 btn btn-primary m-2 "
                    type="submit"
                  >
                    <i className="feather icon-search"></i>Search
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div id="result-block" className="col-md-12">
            {results.hits?.hits.map((item) => (
              <ContentCard
                item={item}
                key={item._source.content_id}
                refresh={handleSearch}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
