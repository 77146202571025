import React from "react";
import { Helmet } from "react-helmet";
import SignInForm from "../components/sign_in/SignInForm";

export default function SignIn() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign In | FootyLight RSS Dashboard</title>
      </Helmet>

      <SignInForm />
    </>
  );
}
