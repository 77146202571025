import { useEffect, useContext, useState } from "react";
import { UserDataContext } from "../../contexts/UserDataContext";
import MaterialTable from "material-table";
import { getTaggerData, totalData, taggedData } from "./getData";
import Modal from "@material-ui/core/Modal";
import ContentCard from "../data_tagging_tool/ContentCard";
import { makeStyles } from "@material-ui/core/styles";
import {
  significantOptions,
  categoryOptions,
} from "../data_tagging_tool/options";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import SimpleRating from "../data_tagging_tool/Rating";
import axios from "axios";
import Spinner from "../data_tagging_tool/Spinner";

const ES_URL = process.env.REACT_APP_ES_URL;
const ES_USERNAME = process.env.REACT_APP_ES_USERNAME;
const ES_PASSWORD = process.env.REACT_APP_ES_PASSWORD;

function getModalStyle() {
  return {
    overflowY: "scroll",
    height: "90%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "85%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function DataTaggingSummary() {
  const [tagSelected, setTagSelected] = useState("");
  const [categorySelected, setCategorySelected] = useState([]);
  const [teamSelected, setTeamSelected] = useState([]);
  const [tournamentSelected, setTournamentSelected] = useState([]);
  const [playerSelected, setPlayerSelected] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [rating, setRating] = useState(0);
  const [watched, setWatched] = useState();
  const [gender, setGender] = useState(null);
  const [list, setList] = useState([]);
  const [results, setResults] = useState();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [message, setMessage] = useState("");
  const [teams, setTeams] = useState([{ value: "0", label: "Not Available" }]);
  const [players, setPlayers] = useState([
    { value: "0", label: "Not Available" },
  ]);
  const [teamIds, setTeamIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const userData = useContext(UserDataContext);
  const tableTitle = list.length + " data tagged by " + userData.name;
  const classes = useStyles();

  const fetch = async () => {
    const data = await getTaggerData(userData.userId);
    let temp = [];
    data.map((item) => temp.push(item._source));
    makeList(temp);
    const totaldata = await totalData();
    setTotalCount(totaldata.length);
  };

  const handleOpen = async (id) => {
    setLoading(true);
    const data = await taggedData(id);
    console.log({ data });
    setResults(data);
    setCategorySelected(data.tagged_data.category);
    setTournamentSelected(data.tagged_data.tournament);
    setTeamSelected(data.tagged_data.team);
    setPlayerSelected(data.tagged_data.player);
    setTagSelected({
      label: data.tagged_data.significant_part,
      value: data.tagged_data.significant_part,
    });
    setWatched({
      label: data.tagged_data.watched ? "Yes" : "No",
      value: data.tagged_data.watched,
    });
    setGender({
      label: data.tagged_data.gender,
      value: data.tagged_data.gender,
    });
    setRating(data.tagged_data.rating);
    setTimeout(() => {
      setLoading(false);
      setOpen(true);
    }, 3000);
  };

  const update = (newTag) => {
    console.log("update started");
    let data = {
      script: {
        inline: `ctx._source.is_tagged = params.is_tagged; ctx._source.tagged_data = params.tagged_data; ctx._source.tagger_email = params.tagger_email; ctx._source.tagger_id = params.tagger_id`,
        lang: "painless",
        params: {
          is_tagged: true,
          tagged_data: newTag,
          tagger_email: userData.email,
          tagger_id: userData.userId,
        },
      },
      query: {
        bool: {
          filter: [
            {
              match_phrase: {
                content_id: results.content_id,
              },
            },
          ],
        },
      },
    };
    try {
      setLoading(true);
      const response = axios.post(
        `${ES_URL}/_update_by_query`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
          auth: {
            username: ES_USERNAME,
            password: ES_PASSWORD,
          },
        }
      );
      if (response) {
        console.log("response update", response.data);

        setTimeout(() => {
          setLoading(false);
          setOpen(false);
          setList([]);
          fetch();
        }, 5000);
      }
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };

  const editButtonHandler = () => {
    if (
      !categorySelected.length ||
      !tournamentSelected.length ||
      !teamSelected.length ||
      !playerSelected.length ||
      !tagSelected ||
      !watched ||
      !gender ||
      !rating
    ) {
      setMessage("**All fields need to be selected");
    } else {
      setMessage("");
      const newTag = {
        category: categorySelected,
        tournament: tournamentSelected,
        team: teamSelected,
        player: playerSelected,
        significant_part: tagSelected.value,
        watched: watched.value,
        gender: gender.value,
        rating: rating,
      };
      console.log({ newTag });
      update(newTag);
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchTournaments = async () => {
    await axios
      .get(
        "https://i43no9s5g3.execute-api.us-east-1.amazonaws.com/prod/all-tournaments"
      )
      .then((response) => {
        console.log("tournaments", response.data);
        const data = response.data.map((tournament) => {
          const newTournament = {
            label: tournament.Name,
            value: tournament.GoalServeID,
          };
          return newTournament;
        });
        setTournaments(data);
        setTournaments((pre) => [
          { value: "0", label: "Not Available" },
          ...pre,
        ]);
      })
      .catch((err) => {});
  };

  const getLabels = (data) => {
    let temp = [];
    data.map((item, i) => temp.push(item.label));
    return String(temp);
  };
  const makeList = (data) => {
    console.log("makeList", data);
    data.map((item) => {
      if (item.tagged_data) {
        var newItem = {
          id: item.content_id,
          link: item.content_url,
          source: item.creator,
          title: item.title,
          category: getLabels(item.tagged_data.category),
          tournament: getLabels(item.tagged_data.tournament),
          team: getLabels(item.tagged_data.team),
          player: getLabels(item.tagged_data.player),
          significant_part: item.tagged_data.significant_part,
          gender: item.tagged_data.gender,
          watched: item.tagged_data.watched,
          rating: item.tagged_data.rating,
        };
      } else {
        newItem = {
          id: item.content_id,
          link: item.content_url,
          source: item.creator,
          title: item.title,
        };
      }
      return setList((pre) => [...pre, newItem]);
    });
  };
  const getIds = (list) => {
    let ids = [];
    list.map((el) => ids.push(el.value));
    return ids;
  };

  const getPlayers = async () => {
    console.log({ teamIds });
    let data = JSON.stringify({
      teamids: teamIds,
    });
    let config = {
      method: "post",
      url: "https://i43no9s5g3.execute-api.us-east-1.amazonaws.com/prod/team-squad",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then((response) => {
        console.log("players", response.data);
        // eslint-disable-next-line array-callback-return
        response.data.map((team) => {
          const newPlayer = {
            label: team.Name,
            value: team.PlayerID,
          };
          setPlayers((pre) => [...pre, newPlayer]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTeams = (tournamentIds) => {
    let data = JSON.stringify({
      tournaments: tournamentIds,
    });
    let config = {
      method: "post",
      url: "https://i43no9s5g3.execute-api.us-east-1.amazonaws.com/prod/tournament-teams",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log("teams", response.data);
        let teamids = [];
        // eslint-disable-next-line array-callback-return
        response.data.map((team) => {
          const newTeam = {
            label: team.TeamName,
            value: team.TeamID,
          };
          setTeams((pre) => [...pre, newTeam]);
          teamids.push(team.TeamID);
        });
        setTeamIds(teamids);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const tournamentIds = getIds(tournamentSelected);
    console.log({ tournamentIds });
    getTeams(tournamentIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournamentSelected]);

  useEffect(() => {
    getPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamIds]);

  useEffect(() => {
    fetch();
    fetchTournaments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Spinner />;
  } else
    return (
      <div style={{ maxWidth: "100%" }}>
        <h1>Total {totalCount} data has been tagged</h1>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <div class="container ">
              <ContentCard results={results} />
              <div className="mt-3">
                <h6>Category of the video</h6>
                <MultiSelect
                  options={categoryOptions}
                  value={categorySelected}
                  onChange={setCategorySelected}
                  labelledBy="Select"
                />
              </div>
              <div className="mt-3">
                <h6>Related Tournaments</h6>
                <MultiSelect
                  options={tournaments}
                  value={tournamentSelected}
                  onChange={setTournamentSelected}
                  labelledBy="Select"
                />
              </div>
              <div className="mt-3">
                <h6>Related Teams</h6>
                <MultiSelect
                  options={teams}
                  value={teamSelected}
                  onChange={setTeamSelected}
                  labelledBy="Select"
                />
              </div>
              <div className="mt-3">
                <h6>Related Players</h6>
                <MultiSelect
                  options={players}
                  value={playerSelected}
                  onChange={setPlayerSelected}
                  labelledBy="Select"
                />
              </div>
              <div className="mt-3">
                <h6>
                  Which part of the content is most significant for tagging
                  this?{" "}
                </h6>
                <Select
                  value={tagSelected}
                  onChange={setTagSelected}
                  options={significantOptions}
                />
              </div>
              <div className="mt-3">
                <h6>Do you need to watch the video for tagging?</h6>
                <Select
                  value={watched}
                  onChange={setWatched}
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                />
              </div>
              <div className="mt-3">
                <h6>Men Football Content?</h6>
                <Select
                  value={gender}
                  onChange={setGender}
                  options={[
                    { value: "men", label: "Men" },
                    { value: "women", label: "Women" },
                  ]}
                />
              </div>
              <div className="mt-3">
                <h6>Quality of Content</h6>
                <SimpleRating rating={rating} setRating={setRating} />
              </div>
              <h1 style={{ color: "red" }}>{message}</h1>
              <div className="row mt-5">
                <button
                  className="btn btn-primary ml-3"
                  onClick={editButtonHandler}
                >
                  Edit
                </button>
                {/* <button
                  className="btn btn-danger ml-5"
                  onClick={irrelevantButtonHandler}
                >
                  Mark as irrelevant
                </button> */}
              </div>
            </div>
          </div>
        </Modal>
        <MaterialTable
          columns={[
            { title: "Id", field: "id" },
            { title: "Link", field: "link" },
            { title: "Source", field: "source" },
            { title: "Title", field: "title" },
            { title: "Category", field: "category" },
            { title: "Tournament", field: "tournament" },
            { title: "Team", field: "team" },
            { title: "Player", field: "player" },
            { title: "Significant Part", field: "significant_part" },
            { title: "Gender", field: "gender" },
            { title: "Watched", field: "watched" },
            { title: "Rating", field: "rating" },
          ]}
          data={list}
          title={tableTitle}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                // Do save operation
                console.log(rowData.title);
                handleOpen(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
          }}
        />
      </div>
    );
}
