import React from "react";
import { Helmet } from "react-helmet";
import Dash from "../components/dashboard";

export default function Dashboard() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FootyLight RSS Dashboard</title>
      </Helmet>
      <Dash/>
    </>
  );
}
