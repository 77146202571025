import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { UserDataContext } from "../../contexts/UserDataContext";
import axios from "axios";
import Notiflix from "notiflix";
import querystring from "querystring";

const API_URL = process.env.REACT_APP_API_URL;

export default function UpdatePassword() {
  const userData = useContext(UserDataContext);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [loading, setLoading] = useState(false);

  Notiflix.Notify.init({
    position: "center-bottom",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const token = userData.sessionToken;

    let config = {
      method: "post",
      url: `${API_URL}/auth/updatePassword`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: querystring.stringify({
        password: oldPassword,
        newPassword: newPassword,
      }),
    };

    axios(config)
      .then(async (response) => {
        Notiflix.Notify.success("Updated Successfully");
        setLoading(false);
        setOldPassword("");
        setNewPassword("");
        setNewPassword2("");
        return;
      })
      .catch((error) => {
        console.log(error);
        Notiflix.Notify.failure(
          `Updating Failed\n ${error.response.data.data}`
        );
        setLoading(false);
        return;
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Update Password | FootyLight RSS Dashboard</title>
      </Helmet>
      <div>
        <div className="row align-item-center justify-content-center">
          <div className="col-md-6 card p-4">
            <form className="pr-3" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Current Password</label>
                <input
                  type="password"
                  required
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="form-control"
                  placeholder="Enter current password..."
                />
              </div>
              <div className="mb-3">
                <label className="form-label">New Password</label>
                <input
                  type="password"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="form-control"
                  placeholder="Enter new password..."
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Retype New Password</label>
                <input
                  type="password"
                  required
                  value={newPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                  className="form-control"
                  placeholder="Re-enter new password..."
                />
                {newPassword !== newPassword2 && newPassword2.length > 0 ? (
                  <h6 className="alert alert-danger my-2">
                    New passwords not matched
                  </h6>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3">
                <div className="mb-3">
                  <button
                    className="btn btn-success text-dark"
                    type="submit"
                    disabled={
                      newPassword !== newPassword2 && newPassword2.length > 0
                    }
                  >
                    {loading ? "Updating..." : "Update Password"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
