import React, { useState } from "react";
import ExpandedCard from "./ExpandedCard";
import PreviewCard from "./PreviewCard";
export default function ContentCard({ item, refresh }) {
  const [expanded, setExpanded] = useState(false);

  if (expanded) {
    return (
      <ExpandedCard item={item} setExpanded={setExpanded} refresh={refresh} />
    );
  }

  return <PreviewCard item={item} setExpanded={setExpanded} />;
}
