import React, { useState, useEffect } from "react";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import axios from "axios";
import Notiflix from "notiflix";
import { UserDataContext } from "./contexts/UserDataContext";

const API_URL = process.env.REACT_APP_API_URL;

function App() {
  Notiflix.Loading.init({
    customSvgUrl: "/logo_loader.svg",
    svgSize: "80px",
    backgroundColor: "rgb(27, 32, 41)",
  });

  const [authenticated, setAuthenticated] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    Notiflix.Loading.custom();
    const token = localStorage.getItem("FL_RSS_SESSION");

    if (token) {
      axios
        .get(`${API_URL}/auth/me`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setUserData(response.data.data);
          setAuthenticated(true);
        })
        .catch((err) => {
          console.log(err);
          localStorage.clear();
          setAuthenticated(false);
        })
        .finally(() => Notiflix.Loading.remove());
    } else {
      setAuthenticated(false);
      Notiflix.Loading.remove();
    }
  }, []);

  if (authenticated === null) {
    return "";
  }

  return authenticated ? (
    <UserDataContext.Provider value={userData}>
      <Dashboard />
    </UserDataContext.Provider>
  ) : (
    <SignIn />
  );
}

export default App;
