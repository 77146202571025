import { React, useContext } from "react";
import { UserDataContext } from "../../contexts/UserDataContext";
import DataTaggingSummary from "./DataTaggingSummary";
import DataTaggingSummaryAdmin from "./DataTaggingSummaryAdmin";

const IndexSummary = () => {
  const {email} = useContext(UserDataContext);
  console.log();
  const admins = [
    "mahmud@footylight.com",
    "md@footylight.com",
    "niloy@footylight.com",
    "erfan@footylight.com",
    "faisal@footylight.com",
    "tapu@footylight.com",
    "asif.u.joy@gmail.com",
  ];
  return (
    <div>
      {admins.includes(email) ? (
        <DataTaggingSummaryAdmin/>
      ) : (
        <DataTaggingSummary/>
      )}
    </div>
  );
};
export default IndexSummary;
