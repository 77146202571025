import React, { useState } from "react";
import topList from "./data.json";
import "./style.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import Notiflix from "notiflix";
import ContentCard from "../ContentFinder/ContentCard";

const ES_URL = process.env.REACT_APP_ES_URL;
const ES_USERNAME = process.env.REACT_APP_ES_USERNAME;
const ES_PASSWORD = process.env.REACT_APP_ES_PASSWORD;

export default function TopPlayers() {
  const [selected, setSelected] = useState("");
  const [results, setResults] = useState({});

  var oldDate = new Date();
  oldDate.setDate(oldDate.getDate() - 100);

  const getBody = async (selected) => {
    const body = {
      version: true,
      size: 200,
      sort: [
        {
          published_on: {
            order: "desc",
            unmapped_type: "boolean",
          },
        },
      ],
      stored_fields: ["*"],
      script_fields: {},
      docvalue_fields: [
        {
          field: "published_on",
          format: "date_time",
        },
      ],
      _source: {
        excludes: [],
      },
      query: {
        bool: {
          must: [],
          filter: [
            selected === ""
              ? ""
              : {
                  multi_match: {
                    type: "phrase",
                    query: selected,
                    lenient: true,
                  },
                },
            {
              match_phrase: {
                is_relevant: true,
              },
            },
            {
              range: {
                published_on: {
                  gte: oldDate.toISOString(),
                  lte: new Date().toISOString(),
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
      highlight: {
        pre_tags: ["@kibana-highlighted-field@"],
        post_tags: ["@/kibana-highlighted-field@"],
        fields: {
          "*": {},
        },
        fragment_size: 2147483647,
      },
    };

    return body;
  };

  const handleSearch = async (item) => {
    setSelected(item);

    Notiflix.Block.dots("div#result-block", "Loading...", {
      svgSize: "60px",
      svgColor: "#444444",
      messageColor: "#444444",
      backgroundColor: "#F3F6F9DD",
    });

    const body = await getBody(item);

    setResults({});

    await axios
      .post(`${ES_URL}/_search?pretty=true`, JSON.stringify(body), {
        headers: {
          "Content-type": "application/json",
        },
        auth: {
          username: ES_USERNAME,
          password: ES_PASSWORD,
        },
      })
      .then((response) => {
        setResults(response.data);
        Notiflix.Block.remove("div#result-block");
      })
      .catch((err) => {
        console.log(err);
        Notiflix.Report.failure("Something went wrong");
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Players | FootyLight RSS Dashboard</title>
      </Helmet>

      <div className="row">
        <div className="col-md-3">
          <div
            className="card"
            style={{
              height: "88vh",
              borderRadius: "15px",
              maxHeight: "88vh",
              overflow: "scroll",
            }}
          >
            <div className="card-body">
              {topList["top-players"].map((item) => (
                <p
                  className={`top-item py-2 px-3 rounded my-1 ${
                    selected === item ? "active" : ""
                  }`}
                  onClick={() => {
                    handleSearch(item);
                  }}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div
            id="result-block"
            className="col-md-12"
            style={{
              maxHeight: "88vh",
              overflow: "scroll",
              minHeight: "200px",
            }}
          >
            {results.hits?.hits.map((item) => (
              <ContentCard
                item={item}
                key={item._source.content_id}
                refresh={handleSearch}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
