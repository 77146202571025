import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {totalData} from "./getData"

export default function DataTaggingSummary() {
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetch = async () => {
    const total_data = await totalData();
    console.log({total_data})
    let temp = [];
    total_data.map((item) => temp.push(item._source));
    makeList(temp);
    setTotalCount(total_data.length);
  };
  // const totalData = async () => {
  //   let data = JSON.stringify({
  //     sort: [{ published_on: { order: "desc" } }],
  //     size: 10000,
  //     query: {
  //       bool: {
  //         must: [],
  //         filter: [
  //           {
  //             match_phrase: {
  //               is_tagged: true,
  //             },
  //           },
  //         ],
  //         should: [],
  //         must_not: [],
  //       },
  //     },
  //   });
  //   var config = {
  //     method: "post",
  //     url: "https://search-footylight-rss-vufmrxvr2vezqgsqymsrlqf2re.us-east-1.es.amazonaws.com/content/_search",
  //     headers: {
  //       Authorization: "Basic ZmwtYWRtaW46RjAwdHlsaWdodF9FUw==",
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   try {
  //     const response = await axios(config);
  //     console.log(response.data.hits);
  //     return response.data.hits;
  //   } catch (e) {
  //     return [];
  //   }
  // };
  const getLabels = (data) => {
    let temp = [];
    data.map((item, i) => temp.push(item.label));
    return String(temp);
  };
  const makeList = (data) => {
    console.log("makeList", data);
    data.map((item) => {
      if (item.tagged_data) {
        var newItem = {
          id: item.content_id,
          link: item.content_url,
          source: item.creator,
          title: item.title,
          tagger_email: item.tagger_email,
          category: getLabels(item.tagged_data.category),
          tournament: getLabels(item.tagged_data.tournament),
          team: getLabels(item.tagged_data.team),
          player: getLabels(item.tagged_data.player),
          significant_part: item.tagged_data.significant_part,
          gender: item.tagged_data.gender,
          watched: item.tagged_data.watched,
          rating: item.tagged_data.rating,
        };
      } else {
        newItem = {
          id: item.content_id,
          link: item.content_url,
          source: item.creator,
          title: item.title,
          tagger_id: item.tagger_email,
        };
      }
      return setList((pre) => [...pre, newItem]);
    });
  };
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={{ maxWidth: "100%" }}>
      <h1>Total {totalCount} data has been tagged</h1>
      <MaterialTable
        columns={[
          { title: "Id", field: "id" },
          { title: "Tagger", field: "tagger_email" },
          { title: "Source", field: "source" },
          { title: "Title", field: "title" },
          { title: "Category", field: "category" },
          { title: "Tournament", field: "tournament" },
          { title: "Team", field: "team" },
          { title: "Player", field: "player" },
          { title: "Significant Part", field: "significant_part" },
          { title: "Gender", field: "gender" },
          { title: "Watched", field: "watched" },
          { title: "Rating", field: "rating" },
          { title: "Link", field: "link" },
        ]}
        data={list}
        title={"All tagged Data"}
      />
    </div>
  );
}
