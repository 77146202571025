import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserDataContext } from "../../contexts/UserDataContext";
import Notiflix from "notiflix";
import routeMap from "./data.json";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export default function Header(props) {
  const userData = useContext(UserDataContext);
  const [title, setTitle] = useState("");
  const location = useLocation();

  useEffect(() => {
    setTitle(routeMap[location.pathname]);
  }, [location.pathname]);

  const handleSignOut = () => {
    Notiflix.Confirm.show(
      "Are you sure?",
      "You're about to sign out from this application.",
      "Sign Out",
      "Cancel",

      // ok button callback
      function () {
        Notiflix.Loading.init({
          svgColor: "#77e046",
        });
        Notiflix.Loading.circle();

        const token = userData.sessionToken;

        axios
          .post(
            `${API_URL}/auth/signOut`,
            {},
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            localStorage.clear();
            window.location.replace("/");
          })
          .catch((err) => {
            console.log(err);
            localStorage.clear();
          })
          .finally(() => Notiflix.Loading.remove());
      },

      // cancel button callback
      function () {
        return;
      },
      {
        width: "320px",
        borderRadius: "8px",
      }
    );
  };

  return (
    <header
      className="navbar pcoded-header navbar-expand-lg navbar-light border border-bottom-2"
      style={{ zIndex: "99" }}
    >
      <div className="m-header">
        <a href="/" className="b-brand">
          <div className="b-bg">
            <i className="feather icon-trending-up"></i>
          </div>
          <span className="b-title">FootyLight</span>
        </a>
      </div>
      <a className="mobile-menu" id="mobile-header" href="/">
        <i className="feather icon-more-horizontal"></i>
      </a>
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <span style={{ fontSize: "1.2rem" }}>{title}</span>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li>
            <div className="dropdown drp-user">
              <a href="/#" className="dropdown-toggle" data-toggle="dropdown">
                <img
                  src={`https://avatars.dicebear.com/api/gridy/${userData.name}.svg`}
                  alt="Name"
                  width="30px"
                  height="30px"
                  className="rounded-circle"
                />
                &nbsp; {userData.name?.split(" ")[0]} &nbsp;
              </a>
              <div className="dropdown-menu dropdown-menu-right profile-notification">
                <div className="pro-head bg-white d-flex">
                  <img
                    src={`https://avatars.dicebear.com/api/gridy/${userData.name}.svg`}
                    className="img-radius"
                    alt="User-Profile"
                  />
                  <span>
                    <h5 className="my-0">{userData.name}</h5>
                    <small>{userData.email}</small>
                  </span>
                </div>
                <ul className="pro-body">
                  <li>
                    <Link to="/update-password" className="dropdown-item">
                      <i className="feather icon-settings"></i> Update Password
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="#" className="dropdown-item">
                      <i className="feather icon-user"></i> Profile
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="#"
                      onClick={handleSignOut}
                      className="dropdown-item"
                    >
                      <i className="feather icon-log-out"></i> Sign Out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
}
