import React from "react";
import moment from "moment";
import "./cardStyle.css";

const styles = {
  thumbnail: {
    width: "100%",
    height: "135px",
    background: "url('/thumbnail.svg')",
    overflow: "clip",
  },
  thumbnail_img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  title: {
    whiteSpace: "nowrap",
    lineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  snippet: {
    whiteSpace: "normal",
    lineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#888888",
  },
  expanded_thumbnail: {
    width: "100%",
    height: "450px",
    background: "url('/thumbnail.svg')",
    overflow: "clip",
  },
};

export default function PreviewCard({ item, setExpanded }) {
  return (
    <div
      className="row my-4 align-items-center mx-2 content-card"
      onClick={() => setExpanded(true)}
    >
      <div className="col-md-2 p-0" style={styles.thumbnail}>
        <img
          src={item._source.thumbnail}
          alt={item._source.creator}
          style={styles.thumbnail_img}
        />
      </div>
      <div className="col-md-7">
        <h5 style={styles.title}>{item._source.title}</h5>
        <p style={styles.snippet}>{item._source.snippet.slice(0, 200)}...</p>
        <div className="d-flex align-items-center">
          <h5 className="mr-3 d-flex align-items-center">
            <i className="feather icon-user"></i>&nbsp;
            <small>{item._source.creator}</small>
          </h5>
          <h5 className="mx-3 d-flex align-items-center">
            <img
              src={`https://api.statvoo.com/favicon/?url=${item._source.source}.com`}
              alt={item._source.source}
              height="22"
            />
            &nbsp;
            <small>{item._source.source}</small>
          </h5>
          <h5 className="mx-3 d-flex align-items-center">
            <i className="feather icon-clock"></i>&nbsp;
            <small>{moment(item._source.published_on).fromNow()}</small>
          </h5>
        </div>
      </div>
      <div className="col-md">
        <button
          className="btn btn-outline-secondary d-flex align-items-center justify-content-center w-100 px-1"
          onClick={() => setExpanded(true)}
        >
          <i
            className="feather icon-play-circle"
            style={{ fontSize: "1.2rem" }}
          ></i>
          Preview Content
        </button>
      </div>
    </div>
  );
}
