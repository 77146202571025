import axios from "axios";

export const getTaggerData = async (userId) => {
  let data = JSON.stringify({
    sort: [{ published_on: { order: "desc" } }],
    size: 10000,
    query: {
      bool: {
        must: [
          {
            match_phrase: {
              is_tagged: true,
            },
          },
        ],
        filter: [
          {
            match_phrase: {
              tagger_id: userId,
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              is_relevant: false,
            },
          },
        ],
      },
    },
  });
  var config = {
    method: "post",
    url: "https://search-footylight-rss-vufmrxvr2vezqgsqymsrlqf2re.us-east-1.es.amazonaws.com/content/_search",
    headers: {
      Authorization: "Basic ZmwtYWRtaW46RjAwdHlsaWdodF9FUw==",
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    console.log(response.data.hits.total.value);
    return response.data.hits.hits;
  } catch (e) {
    return [];
  }
};

export const totalData = async () => {
  let data = JSON.stringify({
    sort: [{ published_on: { order: "desc" } }],
    size: 10000,
    query: {
      bool: {
        must: [
          {
            match_phrase: {
              is_tagged: true,
            },
          },
        ],
        filter: [
          {
            match_phrase: {
              is_tagged: true,
            },
          },
        ],
        should: [],
        must_not: [{ match_phrase: { is_relevant: false } }],
      },
    },
  });
  var config = {
    method: "post",
    url: "https://search-footylight-rss-vufmrxvr2vezqgsqymsrlqf2re.us-east-1.es.amazonaws.com/content/_search",
    headers: {
      Authorization: "Basic ZmwtYWRtaW46RjAwdHlsaWdodF9FUw==",
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    console.log("get data",response.data.hits.hits);
    return response.data.hits.hits;
  } catch (e) {
    return [];
  }
};

export const taggedData = async (contentId) => {
  console.log({ contentId });
  let data = JSON.stringify({
    sort: [{ published_on: { order: "desc" } }],
    size: 1,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              content_id: contentId,
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  });
  var config = {
    method: "post",
    url: "https://search-footylight-rss-vufmrxvr2vezqgsqymsrlqf2re.us-east-1.es.amazonaws.com/content/_search",
    headers: {
      Authorization: "Basic ZmwtYWRtaW46RjAwdHlsaWdodF9FUw==",
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    console.log("taggedData", response.data.hits.hits[0]._source);
    return response.data.hits.hits[0]._source;
  } catch (e) {
    return [];
  }
};
