import React, { useState, useEffect } from "react";
import axios from "axios";
import Notiflix from "notiflix";
import querystring from "querystring";

const ES_URL = process.env.REACT_APP_ES_URL;
const ES_USERNAME = process.env.REACT_APP_ES_USERNAME;
const ES_PASSWORD = process.env.REACT_APP_ES_PASSWORD;

export default function PublishModal({ item, close, refresh }) {
  const [fixture, setFixture] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [tournament, setTournament] = useState(null);
  const [matches, setMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [loading, setLoading] = useState(false);

  Notiflix.Notify.init({
    position: "center-bottom",
  });

  const fetchFixture = async (date) => {
    setFixture([]);
    setTournament(null);
    setMatches([]);
    setSelectedMatch(null);

    try {
      const selectedDate = new Date(date).toISOString().split("T")[0];

      const { data } = await axios.get(
        `https://footylight.azurewebsites.net/api/Match?matchDay=${selectedDate}`
      );

      setFixture(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFixture(date);
    // eslint-disable-next-line
  }, []);

  const getMatchInfo = async () => {
    const tournamentId = matches[selectedMatch].TournamentID;
    const matchId = matches[selectedMatch].ID;
    const localTeamId = matches[selectedMatch].LocalTeam.ID;
    const visitorTeamId = matches[selectedMatch].VisitorTeam.ID;

    return { tournamentId, matchId, localTeamId, visitorTeamId };
  };

  const publishVideo = async (e) => {
    setLoading(true);

    const { tournamentId, matchId, localTeamId, visitorTeamId } =
      await getMatchInfo();

    let data = querystring.stringify({
      VideoID: item._source.content_id,
      Source: "5",
      Category: "5",
      MatchID: matchId,
      TournamentID: tournamentId,
      LocalTeamID: localTeamId,
      VisitorTeamID: visitorTeamId,
      Title: item._source.title,
      Link: `https://clips.footylight.com/rss/${item._source.content_id}`,
      Thumb: item._source.thumbnail,
      ContentType: "browser",
      ContentUrl: `https://clips.footylight.com/rss/${item._source.content_id}`,
      DmcaLabel: "footylight.com",
      DmcaContent: `https://clips.footylight.com/rss/${item._source.content_id}`,
      DmcaDisclaimer: "https://bit.ly/footylight-dmca",
      OrgnName: "",
      Description: "",
      Duration: "",
      AdEnabled: "1",
      PushSentOn: new Date().toISOString(),
      Disabled: "0",
      SortOrder: "0",
      publishedTime: new Date().toISOString(),
      expiretime: "",
      Created: item._source.published_on,
      Updated: new Date().toISOString(),
      Showcase: "",
      Trending: "",
      EncodedString: "",
      EncodedLong: "",
      Language: "",
      ContentExtra: "",
    });

    let config = {
      method: "post",
      url: "https://footylight.azurewebsites.net/api/Video",
      headers: {
        "auth-token": "footylight",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(async (response) => {
        const status = await updateES(item._source.content_id);
        if (status === "500") {
          Notiflix.Notify.failure("Publishing Failed");
          setLoading(false);
        } else {
          setTimeout(async () => {
            await refresh(e);
            Notiflix.Notify.success("Published Successfully");
            setLoading(false);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        Notiflix.Notify.failure("Publishing Failed");
        setLoading(false);
      });
  };

  const updateES = async (id) => {
    let data = JSON.stringify({
      script: {
        source: "ctx._source.published_on_app = 'true'",
        lang: "painless",
      },
      query: {
        bool: {
          filter: [
            {
              match_phrase: {
                content_id: id,
              },
            },
          ],
        },
      },
    });

    try {
      const response = await axios.post(`${ES_URL}/_update_by_query`, data, {
        headers: {
          "Content-type": "application/json",
        },
        auth: {
          username: ES_USERNAME,
          password: ES_PASSWORD,
        },
      });

      return response.status;
    } catch (error) {
      return "500";
    }
  };

  return (
    <div className="card rounded">
      <div className="card-header">
        <h4 className="mb-0">Publish this video</h4>
      </div>
      <div className="card-body">
        <h5 className="card-subtitle mb-3 text-dark">{item._source.title}</h5>
        <div className="d-flex align-items-center flex-wrap mb-3">
          <h5 className="mr-3 d-flex align-items-center">
            <i className="feather icon-user"></i>&nbsp;
            <small>{item._source.creator}</small>
          </h5>
          <h5 className="mx-3 d-flex align-items-center">
            <img
              src={`https://api.statvoo.com/favicon/?url=${item._source.source}.com`}
              alt={item._source.source}
              height="16"
            />
            &nbsp;
            <small>{item._source.source}</small>
          </h5>
        </div>

        <label>Clips page link</label>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            style={{
              background: "rgba(48, 67, 86, 0.2)",
            }}
            value={`https://clips.footylight.com/rss/${item._source.content_id}`}
            readOnly
          />
          <div className="input-group-append">
            <button
              className="btn btn-dark b-0"
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://clips.footylight.com/rss/${item._source.content_id}`
                );
                Notiflix.Notify.success("Clips page link copied");
              }}
            >
              Copy
            </button>
          </div>
        </div>

        <div className="form-row mt-5">
          <div className="form-group col-md-4">
            <label for="inputCity">Date</label>
            <input
              type="date"
              className="form-control"
              id="inputCity"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
                fetchFixture(e.target.value);
                setTournament(-1);
              }}
              disabled={fixture === []}
            />
          </div>
          <div className="form-group col-md-4">
            <label for="inputState">Tournament</label>
            <select
              id="inputState"
              className="form-control"
              disabled={fixture === [] || !date}
              value={tournament}
              onChange={(e) => {
                setTournament(e.target.value);
                setMatches(fixture[e.target.value].Matches);
                setSelectedMatch(-1);
              }}
            >
              <option disabled selected value="-1">
                Choose...
              </option>
              {fixture.map((item, index) => (
                <option value={index}>{item.Name}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-4">
            <label for="inputState">Match</label>
            <select
              id="inputState"
              className="form-control"
              disabled={fixture === [] || tournament === null}
              value={selectedMatch}
              onChange={(e) => setSelectedMatch(e.target.value)}
            >
              <option selected disabled value="-1">
                Choose...
              </option>
              {matches.map((item, index) => (
                <option value={index}>{`${item.LocalTeam.Name} (${
                  item.LocalTeam.Score || "?"
                } : ${item.VisitorTeam.Score || "?"}) ${
                  item.VisitorTeam.Name
                }`}</option>
              ))}
            </select>
          </div>
        </div>
        <hr />
        <div className="d-flex flex-row-reverse mt-3">
          <button className="btn btn-secondary mx-0" onClick={close}>
            Cancel
          </button>
          <button
            className="btn btn-success text-dark mx-2"
            onClick={(e) => publishVideo(e)}
            disabled={
              loading ||
              matches === [] ||
              selectedMatch === null ||
              selectedMatch === -1
            }
          >
            {loading ? "Publishing..." : "Publish"}
          </button>
        </div>
      </div>
    </div>
  );
}
