import React from "react";
import NavigationMenu from "../nav_menu/NavigationMenu";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ContentFinder from "./ContentFinder/index";
import PublishedContent from "./PublishedContent/index";
import AccessManager from "./AccessManager/index";
import Error404 from "../../pages/404";
import Header from "../header/Header";
import CustomEntry from "./CustomEntry/index";
import TopTournaments from "./CategorizedContent/TopTournaments";
import TopTeams from "./CategorizedContent/TopTeams";
import TopPlayers from "./CategorizedContent/TopPlayers";
import UpdatePassword from "./UpdatePassword";
import DataTaggingTool from "../data_tagging_tool";
import IndexSummary from "../data_tagging_summary";

export default function Dash() {
  return (
    <BrowserRouter>
      <NavigationMenu />
      <Header />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper"></div>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/finder" />}
                  />
                  <Route exact path="/finder" component={ContentFinder} />
                  <Route
                    exact
                    path="/published-content"
                    component={PublishedContent}
                  />
                  <Route exact path="/custom-entry" component={CustomEntry} />
                  <Route
                    exact
                    path="/top-tournaments"
                    component={TopTournaments}
                  />
                  <Route exact path="/data-tagging-tool" component={DataTaggingTool}/>
                  <Route exact path="/data-tagging-summary" component={IndexSummary}/>
                  <Route exact path="/top-teams" component={TopTeams} />
                  <Route exact path="/top-players" component={TopPlayers} />
                  <Route
                    exact
                    path="/access-manager"
                    component={AccessManager}
                  />
                  <Route
                    exact
                    path="/update-password"
                    component={UpdatePassword}
                  />
                  <Route path="/*" component={Error404} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
