import React from "react";
import { Helmet } from "react-helmet";

export default function Error404(props) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 Page Not Found</title>
      </Helmet>

      <div className="container text-center mt-5">
        <div>
          <h1 className="text-secondary" style={{ fontSize: "10rem" }}>
            ⚠️
          </h1>
          <h1 className="text-secondary" style={{ fontSize: "6rem" }}>
            404
          </h1>
          <h4 className="text-secondary">PAGE NOT FOUND</h4>
        </div>
      </div>
    </>
  );
}
