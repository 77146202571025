import React, { useState } from "react";
import axios from "axios";
import querystring from "querystring";
import Notiflix from "notiflix";

const API_URL = process.env.REACT_APP_API_URL;

export default function SignInForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    Notiflix.Notify.init({
      position: "center-bottom",
    });
    Notiflix.Loading.init({
      svgColor: "#77e046",
    });

    Notiflix.Loading.circle();

    axios
      .post(
        `${API_URL}/auth/signIn`,
        querystring.stringify({
          email: email,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem("FL_RSS_SESSION", token);
        window.location.replace("/");
      })
      .catch((err) => {
        console.log(err);
        Notiflix.Notify.failure(err.response.data.data);
      })
      .finally(() => Notiflix.Loading.remove());
  };

  return (
    <div className="auth-wrapper pt-5">
      <div className="auth-content mt-5 ">
        <div className="row text-center align-items-center justify-content-center pb-3">
          <img
            src="logo.svg"
            alt="Footylight"
            width="47"
            className="mb-2 bg-secondary"
            style={{ borderRadius: "22%" }}
          />
          &nbsp;&nbsp;
          <h3>FootyLight</h3>
        </div>
        <div className="card mt-2">
          <div className="card-body">
            <div className="mt-3" />
            <h3 className="mb-4">Sign In</h3>
            <form onSubmit={handleSubmit}>
              <label>Email</label>
              <div className="input-group mb-3">
                <input
                  type="email"
                  required
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="someone@example.com"
                />
              </div>
              <label>Password</label>
              <div className="input-group mb-4">
                <input
                  type="password"
                  required
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="password"
                />
              </div>

              <button className="btn btn-primary shadow-2 mb-4" type="submit">
                Sign In
              </button>
            </form>
            <p className="mb-2 text-muted">
              Forgot password? <a href="/#">Reset</a>
            </p>

            <div className="mb-3" />
          </div>
        </div>
      </div>
    </div>
  );
}
