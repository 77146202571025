/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import Select from "react-select";
import SimpleRating from "./Rating";
import Spinner from "./Spinner";
import { UserDataContext } from "../../contexts/UserDataContext";
import ContentCard from "./ContentCard";
import { categoryOptions, significantOptions } from "./options";

const ES_URL = process.env.REACT_APP_ES_URL;
const ES_USERNAME = process.env.REACT_APP_ES_USERNAME;
const ES_PASSWORD = process.env.REACT_APP_ES_PASSWORD;

export default function DataCard({
  tournaments,
  results,
  refresh,
  setRefresh,
  loading,
  setLoading,
}) {
  const [tagSelected, setTagSelected] = useState("");
  const [categorySelected, setCategorySelected] = useState([]);
  const [teamSelected, setTeamSelected] = useState([]);
  const [tournamentSelected, setTournamentSelected] = useState([]);
  const [playerSelected, setPlayerSelected] = useState([]);
  const [rating, setRating] = useState(0);
  const [watched, setWatched] = useState();
  const [gender, setGender] = useState(null);
  const [message, setMessage] = useState("");
  const [teams, setTeams] = useState([{ value: "0", label: "Not Available" }]);
  const [players, setPlayers] = useState([
    { value: "0", label: "Not Available" },
  ]);

  const userData = useContext(UserDataContext);

  const update = (newTag) => {
    console.log("update started");
    let data = {
      script: {
        inline: `ctx._source.is_tagged = params.is_tagged; ctx._source.tagged_data = params.tagged_data; ctx._source.tagger_email = params.tagger_email; ctx._source.tagger_id = params.tagger_id`,
        lang: "painless",
        params: {
          is_tagged: true,
          tagged_data: newTag,
          tagger_email: userData.email,
          tagger_id: userData.userId,
        },
      },
      query: {
        bool: {
          filter: [
            {
              match_phrase: {
                content_id: results.content_id,
              },
            },
          ],
        },
      },
    };
    try {
      setLoading(true);
      const response = axios.post(
        `${ES_URL}/_update_by_query`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
          auth: {
            username: ES_USERNAME,
            password: ES_PASSWORD,
          },
        }
      );
      if (response) {
        console.log("response update", response.data);
        setTimeout(() => {
          setLoading(false);
          loadHanlder();
          console.log("here");
        }, 3000);
      }
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };
  const loadHanlder = () => {
    refreshStates();
    setRefresh(!refresh);
    window.scrollTo(0, 0);
    console.log("loadHanlder");
  };

  const refreshStates = () => {
    setCategorySelected([]);
    setTournamentSelected([]);
    setTeamSelected([]);
    setPlayerSelected([]);
    setTagSelected([]);
    setWatched([]);
    setGender(null);
    setRating(0);
    setTeams([{ value: "0", label: "Not Available" }]);
    setPlayers([{ value: "0", label: "Not Available" }]);
  };

  const irrelevantButtonHandler = async () => {
    let data = {
      script: {
        inline: `ctx._source.is_relevant=params.is_relevant; ctx._source.tagger_id = params.tagger_id; ctx._source.tagger_email = params.tagger_email`,
        lang: "painless",
        params: {
          is_relevant: false,
          tagger_id: userData.userId,
          tagger_email: userData.email,
        },
      },
      query: {
        bool: {
          filter: [
            {
              match_phrase: {
                content_id: results.content_id,
              },
            },
          ],
        },
      },
    };
    try {
      setLoading(true);
      const response = await axios.post(
        `${ES_URL}/_update_by_query`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
          auth: {
            username: ES_USERNAME,
            password: ES_PASSWORD,
          },
        }
      );
      if (response.data) {
        setTimeout(() => {
          loadHanlder();
          setLoading(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const nextButtonHandler = () => {
    if (
      !categorySelected.length ||
      !tournamentSelected.length ||
      !teamSelected.length ||
      !playerSelected.length ||
      !tagSelected ||
      !watched ||
      !gender ||
      !rating
    ) {
      setMessage("**All fields need to be selected");
    } else {
      setMessage("");
      const newTag = {
        category: categorySelected,
        tournament: tournamentSelected,
        team: teamSelected,
        player: playerSelected,
        significant_part: tagSelected.value,
        watched: watched.value,
        gender: gender.value,
        rating: rating,
      };
      console.log({ newTag });
      update(newTag);
      // setTimeout(loadHanlder(),3000)
      // loadHanlder()
    }
  };

  const skipButtonHandler = () => {
    setLoading(true);
    const ls = localStorage.getItem("footylight_dtt_skipIds");
    console.log(results.content_id);
    let parsedLs = [];
    if (ls) {
      parsedLs = JSON.parse(ls);
    }
    parsedLs.push(results.content_id);
    console.log({ parsedLs });
    localStorage.setItem("footylight_dtt_skipIds", JSON.stringify(parsedLs));
    loadHanlder();
    setLoading(false);
  };

  const getIds = (list) => {
    let ids = [];
    list.map((el) => ids.push(el.value));
    return ids;
  };

  const getPlayers = async (teamIds) => {
    console.log({ teamIds });
    let data = JSON.stringify({
      teamids: teamIds,
    });
    let config = {
      method: "post",
      url: "https://i43no9s5g3.execute-api.us-east-1.amazonaws.com/prod/team-squad",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then((response) => {
        console.log("players", response.data);
        // eslint-disable-next-line array-callback-return
        response.data.map((team) => {
          const newPlayer = {
            label: team.Name,
            value: team.PlayerID,
          };
          setPlayers((pre) => [...pre, newPlayer]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTeams = (tournamentIds) => {
    let data = JSON.stringify({
      tournaments: tournamentIds,
    });
    let config = {
      method: "post",
      url: "https://i43no9s5g3.execute-api.us-east-1.amazonaws.com/prod/tournament-teams",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log("teams", response.data);
        // eslint-disable-next-line array-callback-return
        response.data.map((team) => {
          const newTeam = {
            label: team.TeamName,
            value: team.TeamID,
          };
          setTeams((pre) => [...pre, newTeam]);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    const tournamentIds = getIds(tournamentSelected);
    console.log({ tournamentIds });
    getTeams(tournamentIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournamentSelected]);

  useEffect(() => {
    const teamIds = getIds(teamSelected);
    getPlayers(teamIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSelected]);

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div class="container ">
        <ContentCard results={results} />
        <div className="mt-3">
          <h6>Category of the video</h6>
          <MultiSelect
            options={categoryOptions}
            value={categorySelected}
            onChange={setCategorySelected}
            labelledBy="Select"
          />
        </div>
        <div className="mt-3">
          <h6>Related Tournaments</h6>
          <MultiSelect
            options={tournaments}
            value={tournamentSelected}
            onChange={setTournamentSelected}
            labelledBy="Select"
          />
        </div>
        <div className="mt-3">
          <h6>Related Teams</h6>
          <MultiSelect
            options={teams}
            value={teamSelected}
            onChange={setTeamSelected}
            labelledBy="Select"
          />
        </div>
        <div className="mt-3">
          <h6>Related Players</h6>
          <MultiSelect
            options={players}
            value={playerSelected}
            onChange={setPlayerSelected}
            labelledBy="Select"
          />
        </div>
        <div className="mt-3">
          <h6>
            Which part of the content is most significant for tagging this?{" "}
          </h6>
          <Select
            value={tagSelected}
            onChange={setTagSelected}
            options={significantOptions}
          />
        </div>
        <div className="mt-3">
          <h6>Do you need to watch the video for tagging?</h6>
          <Select
            value={watched}
            onChange={setWatched}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
        </div>
        <div className="mt-3">
          <h6>Men Football Content?</h6>
          <Select
            value={gender}
            onChange={setGender}
            options={[
              { value: "men", label: "Men" },
              { value: "women", label: "Women" },
            ]}
          />
        </div>
        <div className="mt-3">
          <h6>Quality of Content</h6>
          <SimpleRating rating={rating} setRating={setRating} />
        </div>
        <h1 style={{ color: "red" }}>{message}</h1>
        <div className="row mt-5">
          <button className="btn btn-primary ml-3" onClick={nextButtonHandler}>
            Next
          </button>
          <button className="btn btn-primary ml-5" onClick={skipButtonHandler}>
            Skip
          </button>
          <button
            className="btn btn-danger ml-5"
            onClick={irrelevantButtonHandler}
          >
            Mark as irrelevant
          </button>
        </div>
      </div>
    );
  }
}
