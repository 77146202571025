import React from "react";
import moment from "moment";
import "./cardStyle.css";
import Popup from "reactjs-popup";
import PublishModal from "./PublishModal";
import Notiflix from "notiflix";
import axios from "axios";
import ReactPlayer from "react-player";

const ES_URL = process.env.REACT_APP_ES_URL;
const ES_USERNAME = process.env.REACT_APP_ES_USERNAME;
const ES_PASSWORD = process.env.REACT_APP_ES_PASSWORD;

const styles = {
  thumbnail_img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  title: {
    whiteSpace: "nowrap",
    lineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  snippet: {
    whiteSpace: "normal",
    lineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#888888",
  },
  expanded_thumbnail: {
    width: "100%",
    minHeight: "400px",
    overflow: "clip",
  },
};

const markAsIrrelevant = async (e, id, refresh) => {
  Notiflix.Confirm.show(
    "Are you sure?",
    "You're about to mark this content as irrelevant. It won't appear in future search results.",
    "Mark as irrelevant",
    "Cancel",
    async () => {
      Notiflix.Loading.init({
        svgColor: "#77e046",
      });

      Notiflix.Loading.circle();

      let data = JSON.stringify({
        script: {
          source: "ctx._source.is_relevant = 'false'",
          lang: "painless",
        },
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  content_id: id,
                },
              },
            ],
          },
        },
      });

      try {
        const response = await axios.post(`${ES_URL}/_update_by_query`, data, {
          headers: {
            "Content-type": "application/json",
          },
          auth: {
            username: ES_USERNAME,
            password: ES_PASSWORD,
          },
        });
        if (response.data) {
          setTimeout(async () => {
            await refresh(e);
            Notiflix.Notify.success("Marked as irrelevant");
            Notiflix.Loading.remove();
            return;
          }, 2000);
        }
      } catch (error) {
        Notiflix.Notify.failure("Something went wrong");
        Notiflix.Loading.remove();
        return;
      }
    },
    function () {
      return;
    },
    {
      width: "320px",
      borderRadius: "8px",
    }
  );
};

const unpublishContent = async (e, id, refresh) => {
  Notiflix.Confirm.show(
    "Are you sure?",
    "You're about to unpublish this content from Footylight app & website.",
    "Unpublish",
    "Cancel",
    async () => {
      Notiflix.Loading.init({
        svgColor: "#77e046",
      });

      Notiflix.Loading.circle();

      let data = JSON.stringify({
        script: {
          source: "ctx._source.published_on_app = 'false'",
          lang: "painless",
        },
        query: {
          bool: {
            filter: [
              {
                match_phrase: {
                  content_id: id,
                },
              },
            ],
          },
        },
      });

      let config = {
        method: "delete",
        url: `https://footylight.azurewebsites.net/api/Video/Unpublish/${id}`,
        headers: {
          "auth-token": "footylight",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios(config)
        .then(async (res) => {
          const response = await axios.post(
            `${ES_URL}/_update_by_query`,
            data,
            {
              headers: {
                "Content-type": "application/json",
              },
              auth: {
                username: ES_USERNAME,
                password: ES_PASSWORD,
              },
            }
          );
          if (response.data) {
            setTimeout(async () => {
              await refresh(e);
              Notiflix.Notify.success("Content removed from app and website");
              Notiflix.Loading.remove();
              return;
            }, 2000);
          }
        })
        .catch((error) => {
          Notiflix.Notify.failure("Something went wrong");
          Notiflix.Loading.remove();
          return;
        });
    },
    function () {
      return;
    },
    {
      width: "320px",
      borderRadius: "8px",
    }
  );
};

const youTubeIdFromLink = (url) => {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
};

const dugoutIdfromContent = (content) => {
  const id = content.split("dugout-embed-")[1].split('">')[0];
  console.log(id);
  return id;
};

const getEmbed = (source, url, title, body, setBlurBg) => {
  if (source === "YouTube") {
    return (
      <iframe
        width="100%"
        height="100%"
        onLoad={() => setBlurBg(false)}
        src={`https://www.youtube.com/embed/${youTubeIdFromLink(url)}`}
        title={title}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    );
  }
  if (source === "Facebook") {
    return (
      <iframe
        src={`https://www.facebook.com/plugins/post.php?height=420&href=${url}&show_text=false&width=740`}
        width="100%"
        height="420px"
        onLoad={() => setBlurBg(false)}
        title={title}
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameborder="0"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    );
  }
  if (source === "Instagram") {
    return (
      <iframe
        src={`${url}/embed`}
        height="420px"
        onLoad={() => setBlurBg(false)}
        title={title}
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameborder="0"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        allowFullScreen
        allowtransparency
      ></iframe>
    );
  }
  if (source === "Dugout") {
    return (
      <iframe
        width="100%"
        onLoad={() => setBlurBg(false)}
        height="100%"
        src={`https://embed.dugout.com/v2/?p=${dugoutIdfromContent(body)}`}
        title={title}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    );
  }
  if (source === "Opta") {
    return (
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        playing
        controls
        volume={0}
        muted
      />
    );
  }
};

export default function ExpandedCard({ item, setExpanded, refresh }) {
  const [blurBg, setBlurBg] = React.useState(
    item._source.source === "Opta" ? false : true
  );

  Notiflix.Notify.init({
    position: "center-bottom",
  });

  return (
    <div className="row my-4 mx-2 content-card-expanded">
      <div
        className="col-md-6 p-0"
        style={{
          ...styles.expanded_thumbnail,
          background: `url(${item._source.thumbnail})`,
          filter: blurBg ? "blur(30px)" : "none",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
        }}
      >
        {getEmbed(
          item._source.source,
          item._source.content_url,
          item._source.title,
          item._source.content_body,
          setBlurBg
        )}
      </div>
      <div className="col-md-6 py-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 style={styles.title}>{item._source.title}</h5>
          <i
            className="feather icon-x "
            style={{
              fontSize: "1.5rem",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            onClick={() => setExpanded(false)}
          ></i>
        </div>

        <p style={styles.snippet}>{item._source.snippet.slice(0, 300)}...</p>
        <div className="d-flex align-items-center flex-wrap">
          <h5 className="mr-3 d-flex align-items-center">
            <i className="feather icon-user"></i>&nbsp;
            <small>{item._source.creator}</small>
          </h5>
          <h5 className="mx-3 d-flex align-items-center">
            <img
              src={`https://api.statvoo.com/favicon/?url=${item._source.source}.com`}
              alt={item._source.source}
              height="22"
            />
            &nbsp;
            <small>{item._source.source}</small>
          </h5>
          <h5 className="mx-3 d-flex align-items-center">
            <i className="feather icon-clock"></i>&nbsp;
            <small>{moment(item._source.published_on).fromNow()}</small>
          </h5>
        </div>
        <br />
        <label>Clips page link</label>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            value={`https://clips.footylight.com/rss/${item._source.content_id}`}
            readOnly
          />
          <div className="input-group-append">
            <button
              className="btn btn-secondary b-0"
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://clips.footylight.com/rss/${item._source.content_id}`
                );
                Notiflix.Notify.success("Clips page link copied");
              }}
            >
              Copy
            </button>
          </div>
        </div>
        <div className="d-flex flex-wrap">
          <Popup
            trigger={
              <button className="button btn btn-success">
                {window.location.pathname === "/published-content"
                  ? "Republish this video"
                  : "Publish now"}
              </button>
            }
            modal
          >
            {(close) => (
              <PublishModal item={item} close={close} refresh={refresh} />
            )}
          </Popup>
          <a
            href={`https://clips.footylight.com/rss/${item._source.content_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-dark"
          >
            View in clips page
          </a>
          {window.location.pathname === "/published-content" ? (
            <button
              className="btn btn-danger"
              onClick={(e) =>
                unpublishContent(e, item._source.content_id, refresh)
              }
            >
              Unpublish
            </button>
          ) : (
            <button
              className="btn btn-danger"
              onClick={(e) =>
                markAsIrrelevant(e, item._source.content_id, refresh)
              }
            >
              Mark as irrelevant
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
