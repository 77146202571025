import React, { useEffect, useState } from "react";
import axios from "axios";
import DataCard from "./DataCard";

export default function DataTaggingTool() {
  const [results, setResults] = useState({});
  // const [teams, setTeams] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const contentId = localStorage.getItem("footylight_dtt_skipIds") || "[]";
  const skipIds = JSON.parse(contentId);
  const [loading, setLoading] = useState(false);

  const fetch = async (query) => {
    console.log("fetch start");
    const videos = await getVideos(query);
    console.log({ videos });
    setResults(videos[0]._source);
  };

  const getVideos = async (queryString) => {
    let must_not_array = [
      {
        match_phrase: {
          source: "Instagram",
        },
      },
      {
        match_phrase: {
          is_relevant: false,
        },
      },
      {
        match_phrase: {
          is_tagged: true,
        },
      },
    ];
    for (let i = 0; i < skipIds.length; i++) {
      if (skipIds[i] !== null && skipIds[i] !== undefined) {
        must_not_array.push({
          match_phrase: {
            content_id: skipIds[i],
          },
        });
      }
    }
    let data = JSON.stringify({
      sort: [{ published_on: { order: "desc" } }],
      size: 1,
      query: {
        bool: {
          must: [],
          filter: [
            queryString.trim().length === 0
              ? ""
              : {
                  multi_match: {
                    query: queryString,
                    fields: ["title"],
                    type: "cross_fields",
                  },
                },
            {
              range: {
                published_on: {
                  gte: new Date(Date.now() - 31.104e9).toISOString(),
                  lte: new Date(Date.now() - 5.104e9).toISOString(),
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: must_not_array,
        },
      },
    });
    var config = {
      method: "post",
      url: "https://search-footylight-rss-vufmrxvr2vezqgsqymsrlqf2re.us-east-1.es.amazonaws.com/content/_search",
      headers: {
        Authorization: "Basic ZmwtYWRtaW46RjAwdHlsaWdodF9FUw==",
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      setLoading(true);
      const response = await axios(config);
      setLoading(false);
      return response.data.hits.hits;
    } catch (e) {
      setLoading(false);
      return [];
    }
  };

  const fetchTournaments = async () => {
    await axios
      .get(
        "https://i43no9s5g3.execute-api.us-east-1.amazonaws.com/prod/all-tournaments"
      )
      .then((response) => {
        console.log("tournaments", response.data);
        const data = response.data.map((tournament) => {
          const newTournament = {
            label: tournament.Name,
            value: tournament.GoalServeID,
          };
          return newTournament;
        });
        setTournaments(data);
        setTournaments((pre) => [
          { value: "0", label: "Not Available" },
          ...pre,
        ]);
      })
      .catch((err) => {});
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchTournaments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetch(
      "Livepool Manchester City Manchester United Arsenal Chelsea Tottenham Real Madrid Barcelona AC Milan Juventus  Inter Milan Napoli Bayern Munich Borrusia Dortmund PSG"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  if (results.length === 0) return null;
  return (
    <div>
      <DataCard
        tournaments={tournaments}
        results={results}
        refresh={refresh}
        setRefresh={setRefresh}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
}
