import React from "react";
import data from "./data.json";
import { Link, useLocation } from "react-router-dom";

export default function NavigationMenu(props) {
  return (
    <nav className="pcoded-navbar">
      <div className="navbar-wrapper">
        <div className="navbar-brand header-logo">
          <Link to="/" className="b-brand">
            <div className="b-bg">
              <img src="logo.svg" alt="Footylight" width="100%" />
            </div>
            <span className="b-title font-weight-normal">
              FootyLight <sup className="text-secondary">α</sup>
            </span>
          </Link>
        </div>
        <div className="navbar-content scroll-div">
          <ul className="nav pcoded-inner-navbar mt-3">
            <li className="nav-item pcoded-menu-caption">
              <label>RSS Dashboard</label>
            </li>
            {data.map((item) => (
              <NavigationItem item={item} />
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

const NavigationItem = ({ item }) => {
  const location = useLocation();
  const [expand, setExpand] = React.useState(false);

  return (
    <li
      key={item.link}
      className={`nav-item ${item.link === location.pathname ? "active" : ""}`}
    >
      <Link
        to={item.link}
        onClick={() => {
          setExpand(!expand);
        }}
        className="nav-link"
      >
        <span className="pcoded-micon">
          <i className={"feather " + item.icon}></i>
        </span>
        <span className="pcoded-mtext">{item.title}</span>
      </Link>
      {item.sub_menu.length !== 0 && expand ? (
        <ul className="pcoded-submenu py-1">
          {item.sub_menu.map((i) => (
            <li
              className={`pl-4 ${i.link === location.pathname ? "active" : ""}`}
            >
              <Link to={i.link}>
                <span className="pcoded-micon">
                  <i className={"feather " + i.icon}></i>
                </span>
                {i.title}
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </li>
  );
};
